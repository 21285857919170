@import "../../../../styles/variables";

header.mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem 1rem;
  &.inverted {
    background-color: $background-second-color;
  }
}
