@import "../../../../styles/variables";

.logo-wrapper {
  background: $background-color;
  padding: 12px 15px;

  img {
    width: 88.63px;
    height: 67.47px;
  }
}

header.inverted {
  .logo-wrapper {
    background: $background-second-color;
  }
}
