@import "../../../../../styles/variables";

.greetings-wrapper {
  position: relative;
  width: 350px;
  .greetings {
    position:absolute;
    font-size: 0.875rem;
    right: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: center;
    .greetings-name {
      cursor: pointer;
      display:flex;
      align-items: center;
      .down-arrow {
        margin-left: 1rem;
        background-position: center center;
        text-indent: 10000px;
        background-repeat: no-repeat;
        background-image: url('../../../../../assets/svg/arrow-navigation-dropdown-white.svg');
        width: 9px;
        height: 6px;
        display: block;
        overflow: hidden;
        &.show {
          background-image: url('../../../../../assets/svg/arrow-navigation-dropdown-petrol.svg');
        }
      }
    }

    &.show {
      color: $text-second-color;
      background: $background-second-color;
      border-radius: 0.5rem;
      margin-top: -1rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      .greetings-bye-bye {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
