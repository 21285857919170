@import "../../../../styles/variables";

header.desktop {
  padding-bottom: 4rem;
  &.inverted {
    background-color: $background-second-color;
    & > .container {
      border-bottom: 0.125rem solid $text-second-color;
      a {
        color: $text-second-color;
      }
    }
    nav.user-nav span.nav-link{
      &.my-auctions {
        background-image: url("../../../../assets/svg/inverted-heart.svg");
      }
      &.account {
        background-image: url("../../../../assets/svg/inverted-account.svg");
      }
    }
  }
  & > .container {
    position:relative;
    width: 100%;
    border-bottom: 0.125rem solid $text-color;
  }
  .logo-wrapper {
    position: absolute;
    left: calc(50% - 59px);
    top: 38px;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      float:left;
      margin: 0;
      line-height: 1.5rem;
      a {
        font-size: 0.875rem;
      }
    }
  }
  .navigation-wrapper {
    display: flex;
    justify-content: space-between;
    .navigation-left {
      flex: 0 0 50%;
      text-align: left;
    }
    .navigation-right {
      text-align: right;
    }
  }
  nav.page-nav {
    display: block;
    ul li {
      padding: 2rem 0 1.5rem 2.1875rem;
    }
    a {
      text-decoration: none;
      &.active {
        font-weight: bold;
      }
    }
  }
  nav.user-nav {
    ul li {
      padding-top: 2rem;
    }
    span.nav-link {
      overflow: hidden;
      text-indent: 10000px;
      background-position: center center;
      background-repeat: no-repeat;
      display: inline-block;
      &.my-auctions {
        margin-right: 44px;
        width: 20px;
        height: 17px;
        background-image: url("../../../../assets/svg/heart.svg");
      }
      &.account {
        margin-right: 42px;
        width: 19px;
        height: 22px;
        background-image: url("../../../../assets/svg/account.svg");
      }
    }
  }
}
