@font-face {
  font-family: "MarketSans";
  src: url("../assets/fonts/MarketSans-Regular-WebXL.eot");
  src: url("../assets/fonts/MarketSans-Regular-WebXL.woff") format("woff"),
  url("../assets/fonts/MarketSans-Regular-WebXL.woff2") format("woff2"),
  url("../assets/fonts/MarketSans-Regular-WebXL.ttf") format("ttf");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "MarketSans";
  src: url("../assets/fonts/MarketSans-Bold-WebXL.eot");
  src: url("../assets/fonts/MarketSans-Bold-WebXL.woff") format("woff"),
  url("../assets/fonts/MarketSans-Bold-WebXL.woff2") format("woff2"),
  url("../assets/fonts/MarketSans-Bold-WebXL.ttf") format("ttf");
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "MarketSans";
  src: url("../assets/fonts/MarketSans-SemiBold-WebXL.eot");
  src: url("../assets/fonts/MarketSans-SemiBold-WebXL.woff") format("woff"),
  url("../assets/fonts/MarketSans-SemiBold-WebXL.woff2") format("woff2"),
  url("../assets/fonts/MarketSans-SemiBold-WebXL.ttf") format("ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "MarketSans";
  src: url("../assets/fonts/MarketSans-Light-WebXL.eot");
  src: url("../assets/fonts/MarketSans-Light-WebXL.woff") format("woff"),
  url("../assets/fonts/MarketSans-Light-WebXL.woff2") format("woff2"),
  url("../assets/fonts/MarketSans-Light-WebXL.ttf") format("ttf");
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "MarketSans";
  src: url("../assets/fonts/MarketSans-Thin-WebXL.eot");
  src: url("../assets/fonts/MarketSans-Thin-WebXL.woff") format("woff"),
  url("../assets/fonts/MarketSans-Thin-WebXL.woff2") format("woff2"),
  url("../assets/fonts/MarketSans-Thin-WebXL.ttf") format("ttf");
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: "MarketSans-regular";
  src: url("../assets/fonts/MarketSans-Regular-WebXL.eot");
  src: url("../assets/fonts/MarketSans-Regular-WebXL.woff") format("woff"),
  url("../assets/fonts/MarketSans-Regular-WebXL.woff2") format("woff2"),
  url("../assets/fonts/MarketSans-Regular-WebXL.ttf") format("ttf");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "MarketSans-light";
  src: url("../assets/fonts/MarketSans-Light-WebXL.eot");
  src: url("../assets/fonts/MarketSans-Light-WebXL.woff") format("woff"),
  url("../assets/fonts/MarketSans-Light-WebXL.woff2") format("woff2"),
  url("../assets/fonts/MarketSans-Light-WebXL.ttf") format("ttf");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "MarketSans-bold";
  src: url("../assets/fonts/MarketSans-Bold-WebXL.eot");
  src: url("../assets/fonts/MarketSans-Bold-WebXL.woff") format("woff"),
  url("../assets/fonts/MarketSans-Bold-WebXL.woff2") format("woff2"),
  url("../assets/fonts/MarketSans-Bold-WebXL.ttf") format("ttf");
  font-weight: normal;
  font-display: swap;
}
