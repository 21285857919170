@import "../../../../styles/variables";
.link-section {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 71px 0 52px;
    align-items: center;
    max-width: 1320px;
    margin: auto;
    cursor: default;
    &>div {
        width: 33.33%;
    }
    .link-wrapper{
        display: flex;
        flex-direction: column;
        .link {
            margin-bottom: 50px;
            color: $text-forth-color;
            font-size: 22px;
            text-decoration: none;
            cursor: pointer;
            &:last-child{
                margin-bottom: 0;
            }
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .little-link-wrapper {
        display: flex;
        flex-direction: column;
        .link{
            margin-bottom: 20px;
            font-size: $font-size;
            text-decoration: none;
            cursor: pointer;
            &:last-child{
                margin-bottom: 0;
            }
            &:hover {
                text-decoration: underline;
            }
        }
        span{
            cursor: pointer;
        }
    }
    a {
        text-decoration: none;
        cursor: default;
    }
    &.mobile, &.tablet {
        flex-direction: column;
        &>div {
            width: auto;
        }
        .link-wrapper {
            &:first-child {
                margin-bottom: 50px;
            }
        }
        .little-link-wrapper {
            margin-top: 70px;
        }
    }
}
