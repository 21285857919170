@import "../../../styles/variables";
.sidebar-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99991;

  .sidebar-wrapper {
    position: fixed;
    height: 100vh;
    overflow-y: scroll;
    right: 0;
    top: 0;
    background: $background-second-color;
    width: 455px;
    @include media-breakpoint-down(lg) {
      width: 100vw;
    }
    .sidebar-header {
      .sidebar-close {
        position: absolute;
        top: 34px;
        right: 25px;
        cursor: pointer;
      }
    }
  }
}
