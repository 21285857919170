@import "../../../../styles/variables";

.account-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 200px);
  overflow-y: scroll;
  max-width: 276px;
  margin: 100px auto;
  color: $text-second-color;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  .account-sidebar-navigation {
    text-align: center;
    ul{
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin-bottom: 2rem;
        a {
          color: $text-second-color;
          font-weight: normal;
          font-size: 1.875rem;
          line-height: 2.25rem;
          text-decoration: none;
        }
      }
    }
  }
  .login-description {
    text-align: center;
    h3 {
      font-weight: normal;
      font-size: 1.875rem;
      line-height: 2.25rem;
    }
    .login-back {
      position: absolute;
      color: white;
      top: 34px;
      left: 35px;
      text-indent: 10000px;
      overflow: hidden;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("../../../../assets/svg/arrow-navigation-mobile-back.svg");
      width: 35px;
      height: 33px;
    }
  }
  .user-log-out {
    font-size: 1.375rem;
    line-height: 2.5rem;
    text-align: center;
    margin-bottom: 5rem;
    .user-logout{
      text-decoration: underline;
    }
  }
  .login-buttons {
    .button {
      margin-top: 19px;
      width: 100%;
    }
  }
}
