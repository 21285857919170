@import "../../../../styles/variables";

.pay-bar {
  text-align: center;
  background: #f5ca3a;
  a {
    color: $background-color;
    text-decoration: none;
  }
  .pay-bar-arrow {
    display: inline-block;
    padding-left: 0.25rem;
    padding-right: 1rem;
    transition: padding 0.5s;
  }
  &:hover .pay-bar-arrow {
    padding-left: 1rem;
    padding-right: 0.25rem;
  }
}
