@import '../../../styles/variables';

.language-switch {
    font-size: 14px;
    line-height: 16px;
    max-width: 137px;
    margin: auto;
    border-radius: 11px;
    cursor: pointer;
    &:hover {
        .language {
            &.selected {
                border: 1px solid $text-forth-color;
                border-radius: 11px;
            }
        }
    }
    &.opened {
        color: $background-color;
        background-color: #fff;
        .language {
            padding: 12px 25px;
            &.selected {
                border: 1px solid $text-forth-color;
            }
        }
    }
    .language {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid transparent;
        padding: 13px 8px;
        border-radius: 11px;
        .language-flag {
            min-width: 18px;
            width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            border-radius: 50%;
            border: 1px solid #fff;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .dropdown-arrow {
        transform: rotate(90deg);
    }
    .languages-list {
        .language {
            &:hover {
                background-color: antiquewhite;
                text-decoration: underline;
            }
        }
    }
}
