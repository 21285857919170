.button-wrapper {
    .button {
        background-color: #0B414C;
        color: #fff;
        border: 0;
        width: 100%;
        height: 65px;
        border-radius: 32.5px;
        cursor: pointer;
        text-align: center;
        font-size: 22px;
        padding: 0 10px;
    }
}
