@import '../../../styles/variables';

footer {
    margin-top: 1rem;
    padding-top: 1rem;
    text-align: center;
    // position: absolute;
    // left: 0;
    width: 100%;
    .language-switch-wrapper {
        padding-bottom: 58px;
        &.vip-page {
            @media screen and (max-width: 1200px) {
                padding-bottom: 250px;
            }
        }
    }
}
