@import '../../../styles/variables';

.cover {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(33,33,33,0.66);
  display: flex;
  align-items: center;
  justify-content: center;
  .ending-bid-pop-up-confirm {
    height: 380px;
    width: 450px;
    border-radius: 40px;
    display: flex;
    background-color: $background-second-color;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    .close-button {
      top: 37px;
      right: 47px;
    }
    .bid-ending {
      max-width: 300px;
      .message-and-price {
        .title-confirm {
          font-size: 22px;
          line-height: 26px;
          margin-bottom: 10px;
          color: #212121;
        }
        .message-confirm {
          color: #212121;
          padding: 10px 0;
          a{
            color: #212121;
          }
          .label {
            font-size: 25px;
            line-height: 30px;
          }
          .value {
            font-size: 55px;
            line-height: 65px;
          }
        }
      }
      .confirm-bid {
        width: 100%;
        max-width: 298px;
        margin: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        .button {
          background-color: #0B414C;
          color: #ffffff;
        }
      }
      .abort-confirm {
        color: #212121;
        cursor: pointer;
      }
    }
    &.tablet, &.mobile {
      width: 100%;
      height: 100%;
      padding: 200px 46px 120px;
      border-radius: 0;
      box-sizing: border-box;
      .bid-ending {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
        .message-and-price {
          .title-confirm {
            font-size: 30px;
            line-height: 36px;
          }
          .price {
            .label {
              font-size: 20px;
              line-height: 26px;
            }
            .value {
              font-size: 40px;
              line-height: 48px;
            }
          }
        }
        .go-to-event, .pay-now {
          margin: unset;
        }
      }
    }
  }
}
