$font-family: MarketSans, sans-serif, Ariel;
$font-size: 16px;
$text-color: #fff;
$text-second-color: #0B414C;
$text-third-color: #8A8A8A;
$text-forth-color: #A8CEC5;
$text-fifth-color: #DF5B2A;
$background-color: #212121;
$background-second-color: #9DB6B0;
$background-third-color: #F5CA3A;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px
);
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1320px
);

@import "mixin/breakpoints";
@import "mixin/container";
