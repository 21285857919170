.loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  &.relative {
    position: relative;
    width: 100%;
    height: 100%;
    min-height:100px;
  }
  left: 0;
  top: 0;
  img {
    position: absolute;
    width: 100px;
    height: 100px;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
  }
}
